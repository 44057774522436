<template>
  <div class="container">
    <div class="wrapper card__wrapper">
      <div class="card__content">
        <div class="subheader">
          Contact Us
        </div>
        <h2
          class="section-v2__header section-v2__header_v2 section-v2__header_main"
        >
          We Want to Hear from You!
        </h2>
        <div class="after">&nbsp;</div>
        <div class="section-v2__text">
          <div>
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="Name" required>
                <el-input v-model="form.name" placeholder="Name"></el-input>
              </el-form-item>
              <el-form-item label="Email" required>
                <el-input v-model="form.email" placeholder="Email"></el-input>
              </el-form-item>
              <el-form-item label="Message" required>
                <el-input
                  v-model="form.message"
                  type="textarea"
                  :rows="3"
                  placeholder="Message"
                  maxlength="1000"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item prop="g-recaptcha-response" required>
                <div>
                  <GRecaptcha
                    ref="recaptcha"
                    sitekey="6LfE5TAqAAAAAG1FnYC-aUhJBTXAqZLxN1HyGqkO"
                    @getValidateCode="getValidateCode"
                  ></GRecaptcha>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" style="width: 100%;"
                  >Submit</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="card__picture">
        <img
          loading="lazy"
          src="https://clickup.com/images/about/our-mission.svg"
          style="max-width: 100%;"
          alt="Our mission."
          title="While we absolutely love productivity software, we believe productivity, in general, is broken."
        />
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import GRecaptcha from "@/components/GRecaptcha";

export default {
  metaInfo() {
    return {
      title: "Contact Us - " + this.CompanyName
    };
  },

  components: {GRecaptcha},

  mixins: [],

  props: [],
  data() {
    return {
      disabled: true,
      form: {
        name: "",
        email: "",
        Message: "",
        "g-recaptcha-response": null
      }
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    getValidateCode(token) {
      // this.t = value;
      console.log("token:" + token);
      this.form["g-recaptcha-response"] = token;
    }
  }
};
</script>

<style scoped>
.card__picture {
  position: relative;
}
.card__picture:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: url("https://clickup.com/images/collaboration-detection/bg__with-dotted.svg")
    no-repeat top left / cover;
}

.card__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 0;
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.card.card_picture-right .card__content {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  padding: 0;
}
.card__content {
  width: 38%;
}
.subheader {
  color: #7b68ee;
  font: 800 11px / 12px Axiforma, Arial, serif;
  text-align: left;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0;
  margin: 20px 0 15px;
}
.section-v2__header {
  position: relative;
  font: 800 50px / 69px Axiforma, Arial, serif;
  margin: 20px 0 15px 0;
  margin-bottom: 0;
}
.card__wrapper_v2 .card__content > * {
  text-align: left;
}

.section-v2__text {
  color: #7c828d;
  font: 400 14px / 25px, Arial, serif;
  margin: 15px auto;
}
.after {
  content: "";
  display: block;
  position: absolute;
  width: 100px;
  height: 5px;
  border-radius: 7px;
  position: static;
  margin-top: 30px;
  background: linear-gradient(90deg, #9f8fff 100%, #7b68ee 0);
  margin-bottom: 50px;
}

*,
::after,
::before {
  box-sizing: inherit;
}
</style>
